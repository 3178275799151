
import { Component, Prop } from 'vue-property-decorator';
import { clearSession } from '@/lib/auth';
import { mixins } from 'vue-class-component';
import GenericMixin from '@/lib/mixins/GenericMixin';

@Component
export default class TheHeader extends mixins(GenericMixin) {

  @Prop({ type: Boolean, default: false }) isMobile!: boolean;

  isSidebarClosed = false;

  isRightSidePopupOpen = false;

  open = false;

  showModal = false;

  userSettingsPageLink = {
    name: 'UserSettings',
    to: 'UserSettings',
  };

  selectedBoardingItem: any = null;

  onBoardingItems = [
    {
      title: 'Getting started',
      subTitle: 'Explore key features and possibilities.',
      icon: 'onboarding.png',
      type: 'GettingStarted',
      external: true,
      src: 'https://tasq.io/updates',
    },
    {
      title: "What's New",
      subTitle: 'Find out latest product changes.',
      icon: 'whatsnew.png',
      type: 'New',
      external: true,
      src: 'https://tasq.io/updates',
    },
    {
      title: 'Get Help',
      subTitle: "Need assistance? We're here to help.",
      icon: 'gethelp.png',
      type: 'Help',
      external: true,
      src: 'https://tasq.io/updates',
    },
  ];

  logout() {
    clearSession();
    this.$router.push({ name: 'Login' });
  }

  onSideBarClose() {
    this.isSidebarClosed = true;
  }

  toggleSidebar() {
    // @ts-ignore
    // eslint-disable-next-line no-unused-expressions
    this.$refs.sidebar.toggleSidebarMenu();
    this.isSidebarClosed = false;
  }

  handleSidebarItemClick(link) {
    this.$router.push({
      name: link.to,
    });
  }

  closeRightSidePopup() {
    this.isRightSidePopupOpen = false;
  }

  showBoardingModal(item) {
    this.selectedBoardingItem = item;
    this.showModal = true;
  }

  toggle() {
    this.open = !this.open;
  }

}

